<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Supplemental item information</h3>

	<p>The CASE specification states that the “full statement” and “notes” fields of each standard should contain only “plain text”, so that the standard will be readable by users in systems that display the CASE data without applying any formatting to the text of the statement or notes.</p>
	<ul>
		<li>The CASE specification does allow for <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">markdown</a> in these fields, which allows for some basic formatting such as italics, bold face, and bulletted lists—the markdown syntax that is specifically designed to be readable even if the markdown formatting algorithm is not applied.</li>
		<li>And the CASE spec allows for mathematical and scientific formulae to be expressed in statements and notes using <a href="https://latex-tutorial.com/tutorials/amsmath/" target="_blank">LaTeX</a>, since many standards for math and science require complex formulae to be rendered properly to be understandable.</li>
		<li>HTML, links, and images are expressly forbidden to be used in these fields by the CASE specification.</li>
	</ul>
	<p>However, standards issuing agencies often wish to include, for the benefit of educators reviewing standards, additional information along with standards that require additional formatting, links, and/or images. To accommodate this, <span v-html="app_name"/> provides for an “extended CASE field” called <code>supplementalNotes</code> that can include HTML, links, and images.</p>
	<ul>
		<li><span v-html="app_name"/> provides a rich-text editor for this field in the standard Item Editor interface:<img alt="Supplemental Information example image" srcset="/docimages/supplemental_info-1.png 2x" class="k-help-img block"></li>
		<li>When entered, appears on tile...</li>
		<li>The standard label for the field is “Supplemental Information”, but this label can be customized on a framework-by-framework basis using the...</li>
		<li>The data in the supplementalNotes field is not exported...</li>
		<li>When adding an image to the supplemental information Images are base-64 encoded and the image data is stored directly in the text of the supplemental information field, rather than storing the image in a separate file on a server somewhere.  This makes source control much easier.  But to minimize file transfer times, you should choose the smallest image size that makes the image clearly visible.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	